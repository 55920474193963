import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLGlobal, GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, GLUtil, connect, GLAction, alignPop, ComparisonOperator, RoleName, isGuid } from 'gl-commonui';
import { SubmitBtns, SchoolAvatarUploaded, SecondaryTitle, SchoolNotes } from '@app/components';
import { SchoolLocale, GSAdminLocale, LicenseTypeLocale } from '@app/locales/localeid';
import Form from 'antd/lib/form/Form';
import { Input, Select, Checkbox, message, Modal, Button, Table, InputNumber } from 'antd-min';
import { update, reloadCurrent, get, goHome, updateSchoolMaxUnitError } from "@app/states/school/school";
import { StateType } from '@app/states';
import { SchoolModel, SchoolUpdateStatusModel, SchoolUpdateStatus } from '@app/service/schools/models';
import { FormattedMessage } from 'react-intl';
import { GSSchoolAction, GSAdminAction, LicenseTypes, SchoolClassSubscriptionType } from '@app/util/enum';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { PathConfig } from '@app/config/pathconfig';
import { ISchoolService } from '@app/service/schools';
import { lazyInject, TYPES, SchoolHelper, fmtMsg, CONSTS, deepClone, LicenseTypeNameValueMap, LicenseTypeValueNameMap } from '@app/util';
import "./settings.less";
import { fetchTagListAction } from "@app/states/tag-manager/tag-manager";
import { TagEntity, TagListModel } from "@app/service/tag-manager";
import { isNull, isUndefined } from "lodash";

interface SettingsPageProps {
    update: (id) => (d) => void;
    reloadCurrent: (d) => void;
    get: (d) => void;
    goHome: () => void;
    schoolId: string;
    current: SchoolModel;
    schoolUpdateErrorStatus?: SchoolUpdateStatusModel;
    tagList?: { [key: string]: TagListModel[] };
    tagListUpdated?: boolean;
    updateSchoolMaxUnitError: (d) => void;
    fetchTagListAction: typeof fetchTagListAction;
}

interface SettingsPageStates {
    subscriptionOptions?: JSX.Element[] | null;
    subscriptionTypeUsageOptions?: JSX.Element[] | null;
    unitOptions?: JSX.Element[] | null;
    lsUnitOptions?: JSX.Element[] | null;
    isUpdateErrorModalShow: boolean;
    disableSaveButton: boolean
    masterGSLicenses: number[] | [];
    currentGSLicenses: number[] | [];
    masterLSLicenses: number[] | [];
    currentLSLicenses: number[] | [];
}
const defaultGSUnit = 1;
const LSClassic = "LSClassic";
const GSClassic = "GSClassic";
const GSConnect = "GSConnect";
const GSNexus = "GSNexus";
const GSHybridConnect = "GSHybridConnect";
const GSHybridNexus = "GSHybridNexus";
const GSHybridConnectNexus = "GSHybridConnectNexus";

const ErrorModalEnum = {
    MaxUnitError: 1,
    LicenseError: 2
}

@withRouter
@connect(
    ({ school: { current, schoolUpdateErrorStatus }, tagManager: { tagList, tagListUpdated } }: StateType) => ({
        current,
        schoolUpdateErrorStatus,
        tagList,
        tagListUpdated
    }),
    {
        update,
        reloadCurrent,
        get,
        goHome,
        updateSchoolMaxUnitError,
        fetchTagListAction
    }
)
@GLForm.create()
export class SettingsPage extends Component<
RouteComponentProps<any> & SettingsPageProps & GLFormComponentProps,
SettingsPageStates
> {
    @lazyInject(TYPES.ISchoolService)
    service: ISchoolService;

    private appLockStateOptions?: JSX.Element[];
    constructor(props) {
        super(props);
        this.state = {
            subscriptionOptions: this.getSubscriptionOptions(),
            subscriptionTypeUsageOptions: this.getSubscriptionTypeUsageOptions(),
            unitOptions: this.getUnitOptions(),
            lsUnitOptions: this.getLSUnitOptions(),
            isUpdateErrorModalShow: true,
            disableSaveButton: true,
            currentGSLicenses: [],
            masterGSLicenses: [],
            masterLSLicenses: [],
            currentLSLicenses: [],
        };
    }
    componentDidMount() {
        const { schoolId, regionId } = this.props.match.params;
        this.props.get(schoolId);
        if (regionId) {
            this.props.fetchTagListAction({ regionId, entity: TagEntity.School });
        }
        if (isGuid(schoolId)) {
            this.service.getSchoolLicenseTypes(schoolId).then((res) => {
                this.setState({ masterGSLicenses: res.grapeSEEDLicenses })
                this.setState({ currentGSLicenses: res.grapeSEEDLicenses })
                this.setState({ masterLSLicenses: res.littleSEEDLicenses })
                this.setState({ currentLSLicenses: res.littleSEEDLicenses })
            })
        }
    }
    getOption(key, value, text) {
        return (
            <Select.Option key={key} value={value}>
                {text}
            </Select.Option>
        );
    }
    getSubscriptionOptions() {
        const { current } = this.props;
        let schoolSubscriptionType: Map<string, string>;
        if (current.subscriptionType == null) {
            schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType(false);
        } else {
            const isTextbook = current.subscriptionType.toString() == SchoolClassSubscriptionType.Textbook;
            schoolSubscriptionType = isTextbook ? SchoolHelper.generateSchoolSubscriptionType() : SchoolHelper.generateSchoolSubscriptionType(false);
        }
        return [...schoolSubscriptionType.keys()].map((typeKey, index) => {
            return this.getOption(
                index,
                typeKey,
                schoolSubscriptionType.get(typeKey)
            );
        });
    }
    getSubscriptionTypeUsageOptions() {
        const schoolSubscriptionTypeUsage = SchoolHelper.generateSchoolSubscriptionTypeUsage();
        return [...schoolSubscriptionTypeUsage.keys()].map((typeKey, index) => {
            return this.getOption(
                index,
                typeKey,
                schoolSubscriptionTypeUsage.get(typeKey)
            );
        });
    }
    getUnitOptions() {
        const schoolUnit = SchoolHelper.generateSchoolUnit();
        return [...schoolUnit.keys()].map((unitKey, index) => {
            return this.getOption(index, unitKey, schoolUnit.get(unitKey));
        });
    }
    getLSUnitOptions() {
        const schoolUnit = SchoolHelper.generateLSSchoolUnit();
        return [...schoolUnit.keys()].map((unitKey, index) => {
            return this.getOption(index, unitKey, schoolUnit.get(unitKey));
        });
    }
    keyValueMap(value) {
        switch(value) {
            case GSClassic: 
            case LSClassic:
                return LicenseTypes.Classic;
            case GSConnect:return LicenseTypes.Connect;
            case GSNexus: return LicenseTypes.Nexus;
            case GSHybridConnect: return LicenseTypes.ClassicConnectHybrid;
            case GSHybridNexus: return LicenseTypes.ClassicNexusHybrid;
            case GSHybridConnectNexus: return LicenseTypes.ConnectNexusHybrid;
        }
    }
    getGSLicenseTypeKeys(values) {
        let keys = Object.keys(values);
        keys = keys.filter(k => k === GSClassic || k === GSConnect || k === GSNexus || k === GSHybridConnect || k === GSHybridNexus || k === GSHybridConnectNexus);
        let updatedGSLicenseTypes = [];
        keys.forEach(key => {
            if (values[key]) {
                updatedGSLicenseTypes.push(this.keyValueMap(key))
            }
        })
        return updatedGSLicenseTypes;
    }
    getLSLicenseTypeKeys(values) {
        let keys = Object.keys(values);
        keys = keys.filter(k => k === LSClassic);
        let updatedLSLicenseTypes = [];
        keys.forEach(key => {
            if (values[key]) {
                updatedLSLicenseTypes.push(this.keyValueMap(key))
            }
        })
        return updatedLSLicenseTypes;
    }
    onSubmit(form, handleSubmit) {
        return e => {
            e.preventDefault();
            form.validateFields((err, values) => {
                const lsMaxUnitNumber = parseInt(values.lsMaxUnit);
                const selectedGSLicenses = this.getGSLicenseTypeKeys(values);
                const selectedLSLicenses = this.getLSLicenseTypeKeys(values);
                values.grapeSEEDLicenses = [];
                values.littleSEEDLicenses = [];
                const checkAndSubmit = () => {
                    if (lsMaxUnitNumber > 0) {
                        values.lsMaxUnit = (lsMaxUnitNumber * -1) + "";
                    }
                    if (selectedGSLicenses.length) {
                        values.grapeSEEDLicenses = selectedGSLicenses;
                    }
                    if (selectedLSLicenses.length) {
                        values.littleSEEDLicenses = selectedLSLicenses;
                    }
                    if (values.maxUnit == 0 && values.lsMaxUnit == 0) {
                        message.error(
                            fmtMsg({
                                id: GSAdminLocale.SettingErrorNoMaxUnitSelected
                            })
                        );
                    } else if ((values.allowGSMaxUnit && (isUndefined(values.grapeSEEDLicenses) || !values.grapeSEEDLicenses.length)) || (values.allowLSMaxUnit && ((isUndefined(values.littleSEEDLicenses) || !values.littleSEEDLicenses.length)))) {
                        message.error(
                            fmtMsg({
                                id: GSAdminLocale.SettingErrorNoLicenseTypeSelected
                            })
                        );
                    } else {
                        const previousDisabledState = this.props.current.disabled;
                        handleSubmit({
                            ...this.props.current,
                            ...values,
                            image: this.props.current.image,
                            previousDisabledState: previousDisabledState
                        });
                    }
                }
                if (!err) {
                    if (this.props.current.appLockState != values.appLockState) {
                        Modal.confirm({
                            title: fmtMsg({ id: GSAdminLocale.SchoolEditTitle }),
                            content: fmtMsg({
                                id: GSAdminLocale.SchoolAppLockStateChangePrompt
                            }),
                            okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
                            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
                            onOk: () => { checkAndSubmit() }
                        });
                    } else {
                        checkAndSubmit();
                    }
                }
            });
        };
    }
    deleteSchool() {
        const { schoolId, regionId } = this.props.match.params;
        return this.service
            .delete(schoolId)
            .then(data => {
                this.props.history.push({
                    pathname: GLUtil.pathStringify(PathConfig.Region, {
                        regionId
                    })
                });
            })
            .catch(response => { });
    }

    gsMaxUnitOnChange(e) {
        this.props.form.setFieldsValue({ maxUnit: e.target.checked ? defaultGSUnit + '' : "0" });
        this.props.reloadCurrent({ isGSMaxUnit: e.target.checked });

        // if 'GrapeSEED' curriculum checkbox is disabled, mark license types checkboxes as disabled and value: false
        if (!e.target.checked) {
            this.props.form.setFieldsValue({ 
                GSClassic: false,
                GSConnect: false,
                GSNexus: false,
                GSHybridConnect: false,
                GSHybridNexus: false,
                GSHybridConnectNexus: false 
            })
        }
        // get the master GS licenses
        else {
            this.props.form.setFieldsValue({ 
                GSClassic: this.isPresentInMasterLicenseList(LicenseTypes.Classic),
                GSConnect: this.isPresentInMasterLicenseList(LicenseTypes.Connect),
                GSNexus: this.isPresentInMasterLicenseList(LicenseTypes.Nexus),
                GSHybridConnect: this.isPresentInMasterLicenseList(LicenseTypes.ClassicConnectHybrid),
                GSHybridNexus: this.isPresentInMasterLicenseList(LicenseTypes.ClassicNexusHybrid),
                GSHybridConnectNexus: this.isPresentInMasterLicenseList(LicenseTypes.ConnectNexusHybrid) })
        }
    }

    lsMaxUnitOnChange(e) {
        this.props.form.setFieldsValue({ lsMaxUnit: e.target.checked ? CONSTS.LittleSEEDMaxUnit * -1 : 0 });
        this.props.reloadCurrent({ isLSMaxUnit: e.target.checked });

        // if 'LittleSEED' curriculum checkbox is disabled, mark license types  checkboxes as disabled and value: false
        if (!e.target.checked) {
            this.props.form.setFieldsValue({ LSClassic: false })
        }
        // get the master LS licenses
        else {
            this.props.form.setFieldsValue({ LSClassic: this.isPresentInMasterLicenseList(LicenseTypes.Classic, true) })
        }
    }
    checkUniqueness = (rule: any, value: any, callback: (message?: string) => void) => {
        const { schoolId } = this.props.match.params;
        if (value && value > 0) {
            this.service.checkSchoolCodeUniqueness({ code: value }, schoolId)
                .then(data => {
                    if (!data) {
                        callback(fmtMsg(GSAdminLocale.SchoolModelCodeError));
                        return;
                    }
                    callback();
                }).catch(err => {
                    callback(fmtMsg(GSAdminLocale.SchoolModelCodeError));
                    return;
                });
        } else {
            callback();
        }
    }

    onFormChange = () => {
        if (!this.state.disableSaveButton) {
            return;
        }

        this.setState({ disableSaveButton: false });
    }

    isPresentInMasterLicenseList(value: LicenseTypes, isLittleSeed: boolean = false) {

        if (isLittleSeed) {
            if (!this.state.masterLSLicenses) {
                return false;
            }
            return this.state.masterLSLicenses.some(ls => ls === value);    
        }

        if (!this.state.masterGSLicenses) {
            return false;
        }
        return this.state.masterGSLicenses.some(gs => gs === value);
    }

    onGSLicenseTypeChange = (e) => {
        const fieldName: string = e.target.id;  // gsClassic
        const prefix = fieldName.slice(0, 2);   // gs
        const suffix = fieldName.slice(2, fieldName.length) // Classic

        const selectedGSLicenseType = LicenseTypeNameValueMap[suffix];

        let currentGSLicenses = []
        if (isNull(this.state.currentGSLicenses)) {
            currentGSLicenses.push(selectedGSLicenseType);
            this.setState({ currentGSLicenses: currentGSLicenses });
            return;
        }

        currentGSLicenses = deepClone(this.state.currentGSLicenses);
        const hasLicense = currentGSLicenses && currentGSLicenses.some(l => l === selectedGSLicenseType);
        if (hasLicense) {
            currentGSLicenses = currentGSLicenses.filter(l => l !== selectedGSLicenseType);
        }
        else {
            currentGSLicenses.push(selectedGSLicenseType);
        }

        this.setState({ currentGSLicenses: currentGSLicenses });
    }

    onLSLicenseTypeChange = (e) => {
        const fieldName: string = e.target.id;
        const prefix = fieldName.slice(0, 2);
        const suffix = fieldName.slice(2, fieldName.length)

        const selectedLSLicenseType = LicenseTypeNameValueMap[suffix];

        let currentLSLicenses = [];
        if (isNull(this.state.currentLSLicenses)) {
            currentLSLicenses.push(selectedLSLicenseType);
            this.setState({ currentLSLicenses: currentLSLicenses });
            return;
        }

        currentLSLicenses = deepClone(this.state.currentLSLicenses);
        const hasLicense = currentLSLicenses && currentLSLicenses.some(l => l === selectedLSLicenseType);
        if (hasLicense) {
            currentLSLicenses = currentLSLicenses.filter(l => l !== selectedLSLicenseType);
        }
        else {
            currentLSLicenses.push(selectedLSLicenseType);
        }

        this.setState({ currentLSLicenses: currentLSLicenses });
    }

    getErrorStatusType = (errorType: SchoolUpdateStatus) => {
        switch(errorType)
        {
            case SchoolUpdateStatus.GSMaxUnitInvalid:
            case SchoolUpdateStatus.LSMaxUnitInvalid:
            case SchoolUpdateStatus.BothMaxUnitInvalid:
                    return ErrorModalEnum.MaxUnitError;
            case SchoolUpdateStatus.GSLicenseTypeInvalid:
            case SchoolUpdateStatus.LSLicenseTypeInvalid:
            case SchoolUpdateStatus.BothLicenseTypeInvalid:
                    return ErrorModalEnum.LicenseError;
        }
    }

    getErrorModal = (schoolUpdateErrorStatus: SchoolUpdateStatusModel, updateSchoolMaxUnitError) => {

        let title = "";
        let footer = "";

        const errorType = this.getErrorStatusType(schoolUpdateErrorStatus.status);

        if (errorType === ErrorModalEnum.MaxUnitError) {
            title = fmtMsg({ id: GSAdminLocale.SettingErrorSelectedMaxUnitIsLower})
            footer = fmtMsg({ id: GSAdminLocale.SettingInvalidUnitModalClassMsg })
        } else if (errorType === ErrorModalEnum.LicenseError) {
            title = fmtMsg({ id: GSAdminLocale.SettingErrorLicenseTypeAlreadyInUse})
            footer = fmtMsg({ id: GSAdminLocale.SettingInvalidLicenseTypeModalMsg })
        }

        return (
            <div>
                <Modal
                    onCancel={e => { updateSchoolMaxUnitError(null); }}
                    title={title}
                    visible={true}
                    cancelButtonProps={{ hidden: true }}
                    destroyOnClose={true}
                    className="invalid-max-unit-modal"
                    footer={[
                        <div className="invalid-max-unit-modal__modal-fotter-msg">
                            <span>{footer}</span>
                            <Button
                                className="ant-btn ant-btn-primary"
                                key="ok"
                                onClick={e => { updateSchoolMaxUnitError(null); }}
                            >
                                {fmtMsg({ id: GSAdminLocale.CommonButtonOk })}
                            </Button>
                        </div>
                    ]}
                >
                    <div className="invalid-max-unit-modal__content">
                        {schoolUpdateErrorStatus.littleSeedClasses.length >
                            0 && (
                                <div className="invalid-max-unit-modal__section-gs">
                                    <h3>
                                        {fmtMsg({ id: GSAdminLocale.SettingsAllowLSMaxUnit })}
                                    </h3>
                                    <Table
                                        key={"table-ls"}
                                        dataSource={ schoolUpdateErrorStatus.littleSeedClasses }
                                        pagination={false}
                                    >
                                        <Table.Column
                                            width={"40%"}
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidUnitModalCampus })}
                                            dataIndex="campus"
                                            key="campusId"
                                        />
                                        {errorType === ErrorModalEnum.LicenseError && <Table.Column
                                            width={"20%"}
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidLcenseTypeModal })}
                                            dataIndex={LicenseTypeValueNameMap["licenseType"]}
                                            key="licenseType"
                                        />}
                                        <Table.Column
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidUnitModalClass })}
                                            dataIndex="schoolClass"
                                            key="schoolClassId"
                                        />
                                    </Table>
                                </div>
                            )}
                        {schoolUpdateErrorStatus.grapeSeedClasses.length >
                            0 && (
                                <div className="invalid-max-unit-modal__section-gs">
                                    <h3>
                                        {fmtMsg({ id: GSAdminLocale.SettingsAllowGSMaxUnit })}
                                    </h3>
                                    <Table
                                        key={"table-gs"}
                                        dataSource={ schoolUpdateErrorStatus.grapeSeedClasses }
                                        pagination={false}
                                    >
                                        <Table.Column
                                            width={"40%"}
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidUnitModalCampus })}
                                            dataIndex="campus"
                                            key="campusId"
                                        />
                                        {errorType === ErrorModalEnum.LicenseError && <Table.Column
                                            width={"20%"}
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidLcenseTypeModal })}
                                            render={(data) => LicenseTypeValueNameMap[data.licenseType]}
                                            key="licenseType"
                                        />}
                                        <Table.Column
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidUnitModalClass })}
                                            dataIndex="schoolClass"
                                            key="schoolClassId"
                                        />
                                    </Table>
                                </div>
                            )}
                        <h4></h4>
                    </div>
                </Modal>
            </div>
        )
    }

    render() {
        const {
            current = {} as SchoolModel,
            update,
            get,
            goHome,
            form,
            schoolUpdateErrorStatus,
            updateSchoolMaxUnitError
        } = this.props;
        const { regionId } = this.props.match.params;
        const tags = this.props.tagList[`${regionId}-${TagEntity.School}`] || [];
        const renderFormItem = FormHelper.renderFormItem;
        const gsNumberMaxLength = 30;
        const minCount = 0;
        const maxCount = 99;
        const codeMinVal = CONSTS.MIN_SCHOOL_CODE;
        const codeMaxVal = CONSTS.MAX_SCHOOL_CODE;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" }
        };
        this.appLockStateOptions = SchoolHelper.getDefaultAppLockStateOptions();
        const allowLessonPlanViewEdit = GLGlobal.isActionValid(GSSchoolAction.EditAllowLessonPlan);
        const allowScoolCodeEdit = GLGlobal.isActionValid(GSSchoolAction.EditSchoolCode);
        const allowLessonPlanViewRead = GLGlobal.isActionValid(GSSchoolAction.ReadOnlyAllowLessonPlan);
        const isSystemAdmin = GLGlobal.loginInfo() && GLGlobal.loginInfo().profile &&
            (GLGlobal.loginInfo().profile.roles.indexOf(RoleName.systemAdmin) > -1);
            
        const invalidUnitPopUp = schoolUpdateErrorStatus && schoolUpdateErrorStatus.status != SchoolUpdateStatus.Valid && this.getErrorModal(schoolUpdateErrorStatus, updateSchoolMaxUnitError);

        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
        return (
            <>
                <div className="content-layout settings school-edit-form">
                    <SecondaryTitle title={GSAdminLocale.SchoolEditTitle}>
                        <GLAction action={GSAdminAction.DeleteSchool}>
                            <PageTitleDelBtn
                                type="delete"
                                className="delete"
                                labelId={SchoolLocale.BtnDelete}
                                deleteTitleId={
                                    GSAdminLocale.SchoolModelSureDelSchool
                                }
                                delete={() => this.deleteSchool()}
                            />
                        </GLAction>
                    </SecondaryTitle>
                    <GLForm form={form} onSubmit={this.onSubmit(form, update)} onChange={this.onFormChange}>
                        <div className="form-items">
                            <div className="school-setting-basic-form-items">
                                {renderFormItem(
                                    form,
                                    SchoolLocale.SettingsName,
                                    "name",
                                    <Input />,
                                    current.name,
                                    true
                                )}
                                {renderFormItem(
                                    form,
                                    SchoolLocale.SettingsEnName,
                                    "englishName",
                                    <Input />,
                                    current.englishName,
                                    false
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelAppLockState,
                                    "appLockState",
                                    <Select
                                        onChange={this.onFormChange}
                                        size="large"
                                        {...alignPop()}
                                    >
                                        {...this.appLockStateOptions}
                                    </Select>,
                                    current.appLockState,
                                    false
                                )}
                            </div>
                            <div>
                                <SchoolAvatarUploaded
                                    file={current.image}
                                    onRemoveImage={this.onFormChange}
                                    onSubmit={file => {
                                        this.props.reloadCurrent({
                                            image: file
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <FormItemsLayout colTotal={2}>
                            {current.allowSetTeacherContactParent &&
                                renderFormItem(
                                    { ...form, ...options },
                                    SchoolLocale.SettingsAllowTeachersAccessParentInfo,
                                    "allowTeacherMailToParent",
                                    <Checkbox>
                                        <FormattedMessage
                                            id={
                                                SchoolLocale.SettingsAllowTeachersAccessParentInfo
                                            }
                                        />
                                    </Checkbox>,
                                    current.allowTeacherMailToParent
                                )}
                            {current.allowSetParentContactTeacher &&
                                renderFormItem(
                                    { ...form, ...options },
                                    SchoolLocale.SettingsAllowParentMailToTeacher,
                                    "allowParentMailToTeacher",
                                    <Checkbox>
                                        <FormattedMessage
                                            id={
                                                SchoolLocale.SettingsAllowParentMailToTeacher
                                            }
                                        />
                                    </Checkbox>,
                                    current.allowParentMailToTeacher
                                )}
                        </FormItemsLayout>
                        {isSystemAdmin &&
                            <>
                                <FormItemsLayout colTotal={1}>
                                    {renderFormItem(
                                        { ...form, ...options },
                                        SchoolLocale.SettingsAllowLessonPlanView,
                                        "allowLessonPlanView",
                                        <Checkbox disabled={allowLessonPlanViewRead && !isSystemAdmin && !allowLessonPlanViewEdit}>
                                            <FormattedMessage
                                                id={
                                                    SchoolLocale.SettingsAllowLessonPlanView
                                                }
                                            />
                                        </Checkbox>,
                                        current.allowLessonPlanView === true
                                    )}
                                </FormItemsLayout>
                            </>}
                        {current.regionEnableCodeLogin && renderFormItem({
                            form,
                            localeId: GSAdminLocale.SchoolModelSchoolCode,
                            fieldName: "code",
                            formControl: <InputNumber
                                min={codeMinVal}
                                max={codeMaxVal}
                                disabled={!allowScoolCodeEdit}
                                onChange={this.onFormChange}
                            />,
                            initialValue: current.code,
                            required: allowScoolCodeEdit,
                            rules: [{ validator: this.checkUniqueness }],
                            decoratorOptions: { validateTrigger: "onChange" }
                        })}
                        {GLGlobal.isActionValid(GSSchoolAction.EditSchool) && (
                            <>
                                <div className="partial-info">
                                    <FormItemsLayout colTotal={2}>
                                        {renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelCode,
                                            "gsNumber",
                                            <Input></Input>,
                                            current.gsNumber,
                                            false,
                                            [
                                                FormHelper.ruleForCompareLength(
                                                    GSAdminLocale.SchoolModelCode,
                                                    ComparisonOperator.LessOrEqualsThan,
                                                    gsNumberMaxLength,
                                                    false
                                                )
                                            ]
                                        )}
                                        {hasTagManagerPermission && renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelTags,
                                            "tags",
                                            <Select
                                                size="large"
                                                {...alignPop()}
                                                onChange={this.onFormChange}
                                                mode="multiple"
                                                maxTagCount={2}
                                                optionFilterProp="children"
                                            >
                                                {tags.map(tag => (
                                                    <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                                ))}
                                            </Select>,
                                            current.tags || [],
                                            false,
                                            []
                                        )}
                                    </FormItemsLayout>
                                    <div className="curriculum-details">
                                        <div className="curriculum-details__title">
                                            {fmtMsg({
                                                id:
                                                    GSAdminLocale.SettingMaxUnitSettingTitle
                                            })}
                                        </div>
                                        <FormItemsLayout colTotal={2}>
                                            {renderFormItem(
                                                { ...form, ...options },
                                                GSAdminLocale.SettingsAllowGSMaxUnit,
                                                "allowGSMaxUnit",
                                                <Checkbox
                                                    onChange={this.gsMaxUnitOnChange.bind(
                                                        this
                                                    )}
                                                >
                                                    <FormattedMessage
                                                        id={
                                                            GSAdminLocale.SettingsAllowGSMaxUnit
                                                        }
                                                    />
                                                </Checkbox>,
                                                this.props.current.maxUnit && this.props.current.maxUnit != 0
                                            )}

                                            {renderFormItem(
                                                { ...form, ...options },
                                                GSAdminLocale.SettingsAllowLSMaxUnit,
                                                "allowLSMaxUnit",
                                                <Checkbox
                                                    onChange={this.lsMaxUnitOnChange.bind(
                                                        this
                                                    )}
                                                >
                                                    <FormattedMessage
                                                        id={
                                                            GSAdminLocale.SettingsAllowLSMaxUnit
                                                        }
                                                    />
                                                </Checkbox>,
                                                this.props.current.lsMaxUnit && this.props.current.lsMaxUnit != 0
                                            )}
                                            {this.props.current.isGSMaxUnit
                                                ? renderFormItem(
                                                    form,
                                                    GSAdminLocale.SchoolModelGSMaxUnit,
                                                    "maxUnit",
                                                    <Select
                                                        onChange={this.onFormChange}
                                                        size="large"
                                                        {...alignPop()}
                                                    >
                                                        {...this.state
                                                            .unitOptions}
                                                    </Select>,
                                                    this.props.current.maxUnit ? this.props.current.maxUnit : "0",
                                                    true
                                                )
                                                : renderFormItem(
                                                    form,
                                                    GSAdminLocale.SchoolModelGSMaxUnit,
                                                    "maxUnit",
                                                    <Select
                                                        onChange={this.onFormChange}
                                                        disabled
                                                        size="large"
                                                        {...alignPop()}
                                                    >
                                                        {this.getOption(
                                                            "0",
                                                            "0",
                                                            "0"
                                                        )}
                                                    </Select>,
                                                    this.props.current.maxUnit ? this.props.current.maxUnit : 0
                                                )}
                                            {this.props.current.isLSMaxUnit
                                                ? renderFormItem(
                                                    form,
                                                    GSAdminLocale.SchoolModelLSMaxUnit,
                                                    "lsMaxUnit",
                                                    <Select
                                                        onChange={this.onFormChange}
                                                        size="large"
                                                        {...alignPop()}
                                                    >
                                                        {...this.state
                                                            .lsUnitOptions}
                                                    </Select>,
                                                    this.props.current.lsMaxUnit ? this.props.current.lsMaxUnit.toString() : "0",
                                                )
                                                : renderFormItem(
                                                    form,
                                                    GSAdminLocale.SchoolModelLSMaxUnit,
                                                    "lsMaxUnit",
                                                    <Select
                                                        onChange={this.onFormChange}
                                                        disabled
                                                        size="large"
                                                        {...alignPop()}
                                                    >
                                                        {this.getOption(
                                                            "0",
                                                            "0",
                                                            "0"
                                                        )}
                                                    </Select>,
                                                    this.props.current.lsMaxUnit ? this.props.current.lsMaxUnit.toString() : "0"
                                                )}
                                            {
                                                <div className="gsLicenseType">
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.Classic,
                                                            GSClassic,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.Classic}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.Classic) : false
                                                        )
                                                    }
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.Connect,
                                                            GSConnect,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.Connect}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.Connect) : false
                                                        )
                                                    }
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.Nexus,
                                                            GSNexus,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.Nexus}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.Nexus) : false
                                                        )
                                                    }
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.HybridConnect,
                                                            GSHybridConnect,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.HybridConnect}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.ClassicConnectHybrid) : false
                                                        )
                                                    }
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.HybridNexus,
                                                            GSHybridNexus,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.HybridNexus}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.ClassicNexusHybrid) : false
                                                        )
                                                    }
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.HybridConnectNexus,
                                                            GSHybridConnectNexus,
                                                            <Checkbox 
                                                                disabled={!this.props.current.isGSMaxUnit}
                                                                onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                                <FormattedMessage id={LicenseTypeLocale.HybridConnectNexus}/>
                                                            </Checkbox>,
                                                            this.props.current.isGSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.ConnectNexusHybrid) : false
                                                        )
                                                    }
                                                </div>
                                            }
                                            {
                                                <div className="lsLicenseType" style={{ alignSelf: "start" }}>
                                                    {
                                                        renderFormItem
                                                        (
                                                            { ...form, ...options },
                                                            LicenseTypeLocale.Classic,
                                                            LSClassic,
                                                            <Checkbox
                                                                disabled={!this.props.current.isLSMaxUnit}
                                                                onChange={this.onLSLicenseTypeChange.bind(this)}
                                                            >
                                                                <FormattedMessage id={LicenseTypeLocale.Classic}/>
                                                            </Checkbox>,
                                                            this.props.current.isLSMaxUnit ? this.isPresentInMasterLicenseList(LicenseTypes.Classic, true) : false
                                                        )
                                                    }
                                                </div>
                                            }
                                        </FormItemsLayout>
                                    </div>
                                    <div className="visitation-details">
                                        <div className="visitation-details__title">
                                            {fmtMsg({ id: GSAdminLocale.SettingVisitationDetails })}
                                        </div>
                                        <FormItemsLayout colTotal={2}>
                                            {renderFormItem(
                                                form,
                                                GSAdminLocale.SettingOnsiteVisitCount,
                                                "onSiteVisitCount",
                                                <InputNumber
                                                    min={minCount}
                                                    max={maxCount}
                                                    precision={0}
                                                    onChange={this.onFormChange}
                                                />,
                                                this.props.current.onSiteVisitCount,
                                                true
                                            )}
                                            {renderFormItem(
                                                form,
                                                GSAdminLocale.SettingLVACount,
                                                "lvaCount",
                                                <InputNumber
                                                    min={minCount}
                                                    max={maxCount}
                                                    precision={0}
                                                    onChange={this.onFormChange}
                                                />,
                                                this.props.current.lvaCount,
                                                true
                                            )}
                                        </FormItemsLayout>
                                    </div>
                                    <SchoolNotes form={form} defaultNotes={current.notes} />
                                    <Form.Item>
                                        {form.getFieldDecorator("disabled", {
                                            valuePropName: "checked",
                                            initialValue: current.disabled
                                        })(
                                            <Checkbox
                                                disabled={
                                                    current.regionDisabled
                                                }
                                            >
                                                {fmtMsg({
                                                    id:
                                                        GSAdminLocale.SchoolModelDisabled
                                                })}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </div>
                            </>
                        )}

                        <Form.Item>
                            <SubmitBtns
                                isDisabled={this.state.disableSaveButton}
                                onCancel={goHome}
                                submitAction={GSSchoolAction.SaveSetting}
                            />
                        </Form.Item>
                    </GLForm>
                </div>
                {invalidUnitPopUp}
            </>
        );
    }
}
