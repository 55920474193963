import * as React from 'react'
import { GLGlobal, GLLocale } from 'gl-commonui';
import { GLIcon, GLIconProps, renderlabel } from './gl-icon';
import { Modal } from 'antd-min';
import { ModalFuncProps } from 'antd/lib/modal';

export interface PageTitleBtnProps extends GLIconProps {
}
export const PageTitleBtn: React.StatelessComponent<PageTitleBtnProps> = (props) => {
    return (
        <div className={`page-title-btn ${props.className || ""}`}>
            <GLIcon {...props} />
            <span onClick={props.onClick}>{renderlabel(props)}</span>
        </div>
    )
}
export interface EditDelBtnsProps {
    delete: (d?) => void
    deleteTitleId: string,
    confirmModalProps?: ModalFuncProps
    deleteAction?: string
}
export const PageTitleDelBtn: React.StatelessComponent<EditDelBtnsProps & PageTitleBtnProps> = (props) => {
    return <PageTitleBtn {...props} onClick={() => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        Modal.confirm({
            title: fmtMsg({ id: props.deleteTitleId }),
            okText: fmtMsg({ id: GLLocale.Ok }),
            cancelText: fmtMsg({ id: GLLocale.Cancel }),
            onOk: () => { props.delete() },
            ...props.confirmModalProps
        })
    }} />
}
export interface EditResetBtnsProps {
    reset: (d?) => void
    resetTitleId: string,
    confirmModalProps?: ModalFuncProps
    resetAction?: string
}
export const PageTitleResetBtn: React.StatelessComponent<EditResetBtnsProps & PageTitleBtnProps> = (props) => {
    return <PageTitleBtn {...props} component={ResetSvg} onClick={() => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        Modal.confirm({
            title: fmtMsg({ id: props.resetTitleId }),
            okText: fmtMsg({ id: GLLocale.Ok }),
            cancelText: fmtMsg({ id: GLLocale.Cancel }),
            onOk: () => { props.reset() },
            ...props.confirmModalProps
        })
    }} />
}
function ResetSvg() {
    return(
        <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2876" width="16" height="16"><path d="M67.52 455.552v0.448h115.84l0.128-0.448h275.264L276.672 274.944a336 336 0 1 1 182.08 568.448v113.472a448 448 0 1 0-263.04-762.24L64 64v391.552h3.52z" fill="#d81e06" p-id="2877"></path></svg>
    )
}